import { max, parseISO } from 'date-fns'
import { DriverTV } from '../DriversTV'
import { queueServices } from '../../../domain'

export function getDriverCalledMostRecent(drivers: DriverTV[]) {
  const dates = drivers
    .filter(item => item.called_at)
    .map(item => parseISO(item.called_at))

  const mostRecentDate = max(dates)

  return drivers.find(
    item => parseISO(item.called_at).getTime() === mostRecentDate.getTime()
  )
}

export async function fetchDriversInAttendance(companyId: string) {
  const queuesActive = await queueServices.getQueueActiveCompany({
    from: 'company',
    companyId
  })

  return queuesActive
    .filter(item => item?.scheduling)
    .filter(item => item?.scheduling?.truck)
    .map(item => item?.scheduling?.truck?.id)
}

export function formatDriverName(item: DriverTV) {
  const name = item.queue.driver.name.split(' ')[0]

  return {
    ...item,
    queue: {
      ...item.queue,
      driver: {
        ...item.queue.driver,
        name
      }
    }
  }
}
