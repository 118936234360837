import React, { useEffect, useState, useCallback } from 'react'
import { DriverTVCard } from './DriverTVCard'
import styles from '../styles.module.css'
import { format } from 'date-fns'

import calmapLogo from '../assets/calmap-logo.svg'
import nextCargoLogo from '../assets/next-cargo-logo.svg'
import { DriverCalled, TV } from '../DriversTV'
import { useSpeechSynthesis } from '../../../hooks/useSpeechSynthesis'
import webSocketClient from '../../../webSocketClient'
import { useWindowReload } from '../../../hooks'

interface Props {
  lastDriverCalled: DriverCalled | null
  tvList: TV[]
  attendeds: number
}

const DRIVER_CALL_TIMES = 3

export function DriverTVContent({
  lastDriverCalled,
  attendeds,
  tvList
}: Props) {
  useWindowReload()

  const { talk } = useSpeechSynthesis()

  const [sortList, setSortList] = useState(false)

  const [currentDriverCalled, setCurrentDriverCalled] =
    useState<DriverCalled | null>(null)

  const callDriver = useCallback(
    async ({ queue }: DriverCalled) => {
      const { driver, truck } = queue;
  
      // Função auxiliar para pausar a execução por um período
      const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  
      // Cria o áudio uma vez
      const audio = new Audio('./call-driver-tv.mp3');
  
      for (let i = 0; i < DRIVER_CALL_TIMES; i++) {
        try {
          // Tenta reproduzir o áudio
          await audio.play();
        } catch (error) {
          console.error('Erro ao reproduzir áudio', error);
        }
  
        // Aguarda 4 segundos após o áudio
        await delay(4000);
  
        const phrases = [
          `Chamando motorista ${driver.name}`,
          `com a placa ${truck.licensePlate}`
        ];
  
        for (const phrase of phrases) {
          talk(phrase);
        }
  
        // Aguarda 8 segundos antes de chamar novamente
        await delay(8000);
      }
    },
    [talk]
  );    

  useEffect(() => {
    if (lastDriverCalled) {
      setCurrentDriverCalled(lastDriverCalled)
    }
  }, [lastDriverCalled])

  useEffect(() => {
    const handleWebSocketResponse = (message: {
      driverCalled: DriverCalled | null
    }) => {
      if (message.driverCalled) {
        setSortList(true)

        setCurrentDriverCalled(message.driverCalled)
        callDriver(message.driverCalled)
      }
    }

    webSocketClient.on('checkInCallResponse', handleWebSocketResponse)

    return () => {
      setSortList(false)
      webSocketClient.off('checkInCallResponse', handleWebSocketResponse)
    }
  }, [callDriver])

  let panel = sortList
    ? tvList.filter(it => Number(it.driverTv.id) !== currentDriverCalled?.id)
    : currentDriverCalled
    ? tvList.filter(it => Number(it.driverTv.id) !== currentDriverCalled.id)
    : tvList

  const list = panel
    .sort(
      (a, b) =>
        new Date(b.driverTv.called_at).getTime() -
        new Date(a.driverTv.called_at).getTime()
    )
    .slice(0, 10)

  return (
    <div className={styles.content}>
      <section className={styles.next}>
        <div className={styles.images}>
          <img src={nextCargoLogo} alt='' />
          <img src={calmapLogo} alt='' />
        </div>

        <div className={styles.driver}>
          <p>Próximo</p>

          {currentDriverCalled && (
            <div className={styles.details}>
              <time>
                {format(
                  new Date(currentDriverCalled.called_at),
                  "dd/MM '•' HH'h'mm"
                )}
              </time>

              <div className={styles['truck-license-plate']}>
                {currentDriverCalled?.queue?.truck.licensePlate}
              </div>
              <span className={styles.name}>
                {currentDriverCalled?.queue?.driver.name}
              </span>
            </div>
          )}
        </div>
      </section>

      <section className={styles.summary}>
        <div className={styles.grid}>
          {list.map(item => (
            <DriverTVCard
              driverTv={item.driverTv}
              inAttendance={item.inAttendance}
              key={item.driverTv.id}
            />
          ))}
        </div>

        <div className={styles.attended}>
          <span>Em atendimento</span>
          <p>{attendeds}</p>
        </div>
      </section>
    </div>
  )
}
